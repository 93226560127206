import React from 'react';

function Filter({filters, setFilters, gamesdata, filterCount, filtersVisible, setFiltersVisible, ...props}) {
    
    
    const toggleFilterPane = ()=>{
        if(filtersVisible){
            setFiltersVisible(false);
        }else{
            setFiltersVisible(true);
        }
    }
    const clearFilters = ()=>{
        var tmpfilters = ["Favorites"];
        gamesdata.forEach((game)=>{
            var tags = game.tags.split(', ');
            for(var elem of tags){
                if(!(tmpfilters.includes(elem))){
                tmpfilters.push(elem);
                }
            }
        })
        setFilters({all: tmpfilters, require: [], exclude: []});
    }

    const theTags = ()=>{
        var thedivs = [];
        var reqs = [...filters.require];
        var excl = [...filters.exclude];
        const isReq =(tag)=>{
            const reqsindex =  reqs.indexOf(tag);   
            return (reqsindex>=0);
        }
        const isExcl = (tag)=>{
            const exclindex =  excl.indexOf(tag);
            return (exclindex>=0);
        }

        var sortedfilters = [...filters.all];
        sortedfilters.shift();
        sortedfilters.sort();
        sortedfilters = ["Favorites", ...sortedfilters];
        for(let tag of sortedfilters){
            const [isrequired, isexcluded] = [isReq(tag),isExcl(tag)];
            var markerR = "";
            markerR = isReq(tag)? "(R)": markerR;
            var markerE = isExcl(tag)? "(E)": "";
            var markerI = "(I)";
            markerI = (isReq(tag)||isExcl(tag))? "":markerI;
            thedivs.push( 
                <div key = {10000+filters.all.indexOf(tag)} className={`filter-tag filter-${isrequired? "required" : isexcluded? "excluded" : "included"}`} 
                        onClick={()=>{ handleTagClick(tag);}}>
                    <div>{tag}</div>
                    <div style={{marginLeft: '5px', width: '15px'}}>{markerI + markerR + markerE}</div>
                </div> 
            );
        }

        const handleTagClick = (tag)=>{
            var [isrequired, isexcluded] = [isReq(tag),isExcl(tag)];
            var [newrequire, newexclude] = [[...(filters.require)],[...(filters.exclude)]];
            if(isrequired){
                newrequire.splice(newrequire.indexOf(tag),1);  newexclude.push(tag);
                isrequired = false; isexcluded = true;
                //console.log(tag, 'was required, now excluded')
            }else if(isexcluded){
                newexclude.splice(newexclude.indexOf(tag),1);
                isexcluded = false;
                //console.log(tag, 'was excluded, now included');
            }else{
                newrequire.push(tag);
                isrequired = true;
                //console.log(tag, 'was included, now required');
            }
            setFilters({all: filters.all, require: newrequire, exclude: newexclude});
        }




        return thedivs;
        
    }


  return (
    <div className='filter'>
        <div className='button filter-toggle' onClick={toggleFilterPane}>
            Filters  
            <i className={filtersVisible ? "filter-arrow fas fa-square-caret-up" : "filter-arrow fas fa-square-caret-down"}></i>
        </div>
        <div  className={filtersVisible? 'filter-pane': 'filter-pane-hidden'}>
            
            <div className='filter-legend'>
                <div className='button filter-clear' onClick={clearFilters}>Clear</div>
                <div className='filter-include'>(I)nclude</div>
                <div className='filter-require'>(R)equire</div>
                <div className='filter-exclude'>(E)xclude</div>
            </div>
            <span className='click-tags'>Click tags to filter</span>
            <div className='filter-tags'>
                {theTags()}
            </div>
            <div className='filter-count'>{"Showing "+filterCount.toString()+" / "+gamesdata.length.toString()}</div>
        </div>
        
    </div>
  )
}

export default Filter