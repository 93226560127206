import React, {useState} from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,

    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
} from "react-share";
import Message from './Message';

function Share({shareVisible, setShareVisible,...props}) {
    const [showMessage, setShowMessage] = useState(false);

    const copyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setShowMessage(true);
    }
  return (
    <div className={!shareVisible? "share-hidden" : "share-panel"} >
        <i className="share-close fas fa-xmark" onClick={()=>{setShareVisible(false);}}></i>
        <div className="share-buttons">
            <i className="copy-button fas fa-copy" onClick={copyClick}></i>
            <FacebookShareButton  url={String("https://bomb.zone")}><FacebookIcon size={40} round={true}></FacebookIcon></FacebookShareButton>
            <LinkedinShareButton  url={String("https://bomb.zone")}><LinkedinIcon size={40} round={true}></LinkedinIcon></LinkedinShareButton>
            <RedditShareButton  url={String("https://bomb.zone")}><RedditIcon size={40} round={true}></RedditIcon></RedditShareButton>
            <TwitterShareButton  url={String("https://bomb.zone")}><TwitterIcon size={40} round={true}></TwitterIcon></TwitterShareButton>

        </div>
        <Message message="URL copied to clipboard" timeout={1000} showMessage={showMessage} setShowMessage={setShowMessage}></Message>
    </div>
  )
}

export default Share