import React, {useEffect, useState} from 'react';

export function Game({activeApp, showPanel, ...props}) {
  const [app, setApp] = useState({title: "", appURL: ""})
  useEffect(()=>{
    if(activeApp.appURL !== app.appURL){
      setApp({
        title: activeApp.title,
        appURL: activeApp.appURL
      });
      //console.log("Active App Set To: ",activeApp.title);
      //console.log("at:", activeApp.appURL);
    }
  },[activeApp, app.appURL]
  );

  // useEffect(()=>{
  //   console.log('Game URL Changed To',app.appURL)
  // },[app])

  
  return (
      <iframe  src={app.appURL} title={app.appURL} className={showPanel? "gameframe" : "gameframe-full"} frameBorder="0" allowFullScreen={true}></iframe>
  );
};
