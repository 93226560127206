import React from 'react';
import LogoSrc from './BZ-Vert.png';

export default function VertLogo() {
  return (
    <div className="logo-vert-div">
      <img alt="Bomb.Zone" src={LogoSrc} className = "logo-vert"></img>
    </div>
  )
}

