import React from "react";
import Feedback from "../Feedback";

export const Info = () => {
  return (
    <div className="info">
      <p>Welcome to Bomb.Zone, a browser-based web app that quickly links you to other mobile-friendly web apps. An all-in-one stop for mobile-friendly browser-based apps from around the web that work with most modern devices and browsers. Instantly. NO DOWNLOAD OR INSTALLATION REQUIRED!</p>
      <p>The current apps consist of our own (Swabble) and other apps publicly available.</p>
      <p>The external apps are entirely owned and operated by the respective sources and BZ has no control or ownership of the content or the functionality. Any issues with external apps should be reported to the source. If a game simply does not load, please let us know. Individual app info and external link are located in the dropdown of each app link in the BZ menu.</p>
      <p>The selection of app links is subject to change at any moment, but we hope to keep as many as possible</p>
      <p>If you would like to see more apps added, or more features, please consider donating to the team. Inquire below. Thanks!</p>
      <br></br>
      <br></br>
      <Feedback></Feedback>
    </div>
  );
};