import React, {useState} from 'react';
import  {NavLink} from 'react-router-dom';

function Choice({title, appURL, imagelink, description, setActiveApp, setShowPanel, tags, filters, setFilters, setFiltersVisible, favorites, setFavorites, ...props}) {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const changeApp = ()=>{
      setActiveApp({
        title: title,
        appURL: appURL
      }); 
      setShowPanel(false);
    }
    const toggleDetails = ()=>{
      //e.stopPropagation();
      if(detailsVisible){
        setDetailsVisible(false);
      }else{
        setDetailsVisible(true);
      };
    }
    // const stopProp = (e)=>{
    //   e.stopPropagation();
    // }
    
    const handleTagClick = (tag) =>{
        toggleDetails();
        setFilters({all: filters.all, require: [tag], exclude: []})
        setFiltersVisible(true);
    }

    const handleFavoriteClick = (e) =>{
      e.stopPropagation();
      if(favorites){
        var oldfavs = [...favorites];
        var dattoset = {favorites: oldfavs};
        if(favorites.includes(title)){
          
          oldfavs.splice(oldfavs.indexOf(title), 1);
          setFavorites(oldfavs);
          
          var localdat = JSON.parse(localStorage.getItem('BZdata'));
          if(localdat.favorites){
            dattoset = localdat;
            dattoset.favorites = oldfavs;
          }
          localStorage.setItem('BZdata', JSON.stringify(dattoset));
          //console.log('BZdata set to:', JSON.parse(localStorage.getItem('BZdata')));
        }else{
          oldfavs.push(title);
          setFavorites(oldfavs);
          if(JSON.parse(localStorage.getItem('BZdata'))){
            dattoset = JSON.parse(localStorage.getItem('BZdata'));
            dattoset.favorites = oldfavs;
          }
          localStorage.setItem('BZdata', JSON.stringify(dattoset));
          //console.log('BZdata set to:', JSON.parse(localStorage.getItem('BZdata')));
        }
      }
    }

    const gametags = tags.split(', ').sort().map((tag,i)=>{
      return <div className="game-tag" key={1000+i} onClick={()=>{
        handleTagClick(tag);
      }}>{tag}</div>
    })
    
    const isFavorite = ()=>{
      var ret = false;
      if(favorites){
        ret = favorites.includes(title);
      }
      return ret;
    }

    return(
      <div className="game-choice-full" key="{props.key}">
        <i className={(isFavorite())? "favorite-chosen fas fa-star" : "favorite fas fa-star"} onClick={handleFavoriteClick}></i>
        <NavLink to={"/"+title} className="game-choice" onClick={changeApp} > 
          <span className="game-title"><h5>{title}</h5></span>
          <div className="game-image-div">
            <img alt={title} src={imagelink} className = "game-image"></img>
          </div>
        </NavLink>
        <div className="game-info">
          <i className={detailsVisible? "toggle-info fas fa-angle-up" : "toggle-info fas fa-angle-down"} onClick={toggleDetails}></i>
          <div className="game-description">{description}</div>
        </div>
        <div className={detailsVisible? "game-details" : "game-details-hidden"}>
          <div className="game-tags">{gametags}</div>
          <a className="external-link" href={(title==="Swabble")? "" : appURL} target="_blank"  rel="noopener noreferrer"> 
            {(title==="Swabble")? "A BZ original" : "External Link"}
          </a> 
        </div>
        
      </div>
    )
}

export default Choice;