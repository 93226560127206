
import NavBar from "./components/NavBar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Info } from "./components/Pages/Info";
import {Games} from "./components/Pages/Games";
import {Game} from "./components/Pages/Game";
import React, {useState, useEffect, useRef} from "react";
import Logo from "./images/Logo";
import VertLogo from "./images/VertLogo";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
//import ReactFullscreen from 'react-easyfullscreen';

function App() {
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showPanel, setShowPanel] = useState(true  );
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [gamesdata, setGamesdata] = useState([]);
  const [activeApp, setActiveApp] = useState({
      title: 'Swabble'
    , appURL: 'https://playcanv.as/p/B2pvMi57/'
  });

  const FShandle = useFullScreenHandle();

  const stateRef = useRef();
  stateRef.current = [width, height];

  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      //console.log('React Size Changed: ',stateRef.current);
  }

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  });

  useEffect(()=>{
    getGameList();
  },[])
  
  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('BZdata'))){
      setActiveApp(JSON.parse(localStorage.getItem('BZdata')).lastApp)
    }
    setTimeout(() => {
      setShowPanel(false);
    }, 2000);
  }, [gamesdata])


  // 
  useEffect(()=>{
    var dattoset = {};
    if(JSON.parse(localStorage.getItem('BZdata'))){
      dattoset = JSON.parse(localStorage.getItem('BZdata'));
    }
    dattoset.lastApp = activeApp;
    localStorage.setItem('BZdata', JSON.stringify(dattoset));
  },[activeApp]);
  
  const getGameList = ()=>{
    fetch('https://bomb.zone/api/gamesdata', {
      method: 'GET',
      headers: { Accept: 'application/json', }
    },
    ).then(response => {
      if (response.ok) {
        response.json().then(json => {
          setGamesdata(json.data);
         // console.log("gamedata from server:", json.data)
        }).catch((error) => {
          window.alert("ERROR: Response ok, but json conversion not! " + error.message);
          //console.error('Error:', error);
        });
      }
    }).catch((error) => {
      window.alert("ERROR: Response not ok! " + error.message);
      //console.error('Error:', error);
    });
  }

  const hideThePanel = function(){
    setShowPanel(false);
  }
  
  const showThePanel = () => {
    setShowPanel(true);
  }

  return (
    <>
      <FullScreen handle={FShandle}>
        {/* {({ ref, onRequest, onExit }) => ( */}
          <Router>
            <main  style={{height: height}} className="main"> {/*ref ={ref}*/}
              <div className={(showPanel)? "panel": "panel-hidden"} >
                <div className="pages">
                  <NavBar isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} FShandle={FShandle}/> {/*onRequest={onRequest} onExit={onExit}/>*/}
                  <div className="page-scroll-container">
                    <Routes>
                      <Route path="/:gamename"     element={<Games setShowPanel={setShowPanel} setActiveApp= {setActiveApp} gamesdata={gamesdata} setGamesdata={setGamesdata}/>} />
                      <Route path="/"     element={<Games setShowPanel={setShowPanel} setActiveApp= {setActiveApp} gamesdata={gamesdata} setGamesdata={setGamesdata}/>} />
                      <Route path="/apps" element={<Games setShowPanel={setShowPanel} setActiveApp= {setActiveApp} gamesdata={gamesdata} setGamesdata={setGamesdata}/>} />
                      <Route path="/info" element={<Info/>} />
                    </Routes>
                  </div>
                  
                </div>
                <div title='Toggle Panel' className="toggle-handle" onClick={()=>{!showPanel? showThePanel(): hideThePanel()}}>
                  <i className={showPanel ? "pc fas fa-square-caret-left" : "pc fas fa-square-caret-right"}></i>
                  <VertLogo></VertLogo>
                  <Logo/>
                  <i className={showPanel ? "mobile fas fa-square-caret-up" : "mobile fas fa-square-caret-down"}></i>
                </div>
              </div>
              <Game activeApp={activeApp} showPanel={showPanel}></Game>
            </main> 
          </Router>
        {/* )} */}
      </FullScreen>
    </>
  );
}

export default App;