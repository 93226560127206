import React, {useEffect, useState, useRef} from "react";
import { useParams } from 'react-router-dom';
// import Filler from '../../images/BZ-Icon.png';
import Custom from '../Custom';
import Filter from '../Filter';
import Choice from '../Choice';


export const Games = ({setActiveApp, setShowPanel, gamesdata, setGamesdata, ...props}) => {
  const [filters, setFilters] = useState({all: ["Favorites"], require: [], exclude: []});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [favorites, setFavorites] = useState([]);

  let { gamename } = useParams();
  const myRef = useRef(null);

  useEffect(() =>{
    var tmpfilters = ["Favorites"];
    gamesdata.forEach((game)=>{
      var tags = game.tags.split(', ');
      for(var elem of tags){
        if(!(tmpfilters.includes(elem))){
          tmpfilters.push(elem);
        }
      }
    })
    setFilters({all: tmpfilters, require: [], exclude: []});
    setAppFromURL();
    var localdat = JSON.parse(localStorage.getItem('BZdata'));
    if(localdat){
      if(localdat.favorites){
        //console.log('pulling from BZdata: ', localdat);
        setFavorites(localdat.favorites);
      }
    }
  }, [gamesdata]);

  useEffect(()=>{
    //console.log('filters changed', filters);
    if(filters.require.length>0){
      var target = myRef.current;
      target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop;
    }

  },[filters])
  
  useEffect(()=>{
    //console.log("favorites changed", favorites);
  },[favorites]);

  const setAppFromURL = ()=>{
    if(gamename){
      var thegames = gamesdata.filter(e => e.name === gamename)
      if (thegames.length > 0) {
        
        setActiveApp({          
          title: thegames[0].name,
          appURL: thegames[0].link
        }); 
        setShowPanel(false);
      }
      
    }
  }

  const serverappsarray = ()=>{
    var returnarray = [];
    if(gamesdata){
      returnarray = gamesdata.filter((el)=>{
        var tags = el.tags.split(', ');
        // console.log('tags before favorites added',tags);
        // console.log('favorites state',favorites)
        if(favorites){
          if(favorites.includes(el.name)){
            tags.push("Favorites");
          }
        }
        return filters.require.every((elem)=>{
          // console.log('tags after Favorites added',tags)
          return (tags.includes(elem));
        })&& !filters.exclude.some((elem2)=>{return (tags.includes(elem2));});
      });
    }

    // file deepcode ignore NoZeroReturnedInSort: <please specify a reason of ignoring this>
    returnarray.sort((a, b) => (a.name > b.name) ? 1 : -1);
    return returnarray;
  }

  const theserverapps = serverappsarray().map((app,i)=>(
    <Choice title={app.name} appURL={app.link} imagelink={app.imageURL} description={app.description} tags={app.tags} key={100+i} setShowPanel={setShowPanel} setActiveApp={setActiveApp} filters={filters} setFilters={setFilters} setFiltersVisible={setFiltersVisible} favorites={favorites} setFavorites={setFavorites}></Choice>
  ));

  return (
    <div ref={myRef} className="games-list">
      <Filter filters={filters} setFilters={setFilters} gamesdata={gamesdata} filterCount={theserverapps.length} filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible}></Filter>
      
      {theserverapps}
      
      {/* {empties} */}
      <Custom setActiveApp={setActiveApp} setShowPanel={setShowPanel}></Custom>
    </div>
  );
};




   
