import React, {useState} from 'react'

function Custom({setActiveApp, setShowPanel, ...props}) {
    const [customInput, setCustomInput] = useState("");
    const updateInputValue = (e)=>{
        setCustomInput(e.target.value);
    }
    const setCustomApp=()=>{
        setActiveApp({
            title: "Custom App",
            appURL: customInput
        })
        setShowPanel(false);
    }
  return (
    <div className='custom'>
        <span className='custom-label'>Custom App Link</span>
        <input className='text-input custom-input' type='url' onChange={e => updateInputValue(e)}></input>
        <div className='button custom-button' onClick={setCustomApp}>Test Link</div>
    </div>
  )
}

export default Custom