import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import Share from "./Share"


function NavBar({isFullscreen, setIsFullscreen, FShandle, ...props}) {//onRequest, onExit, ...props}) {
  const [rootActive, setRootActive] = useState(true);
  const [shareVisible, setShareVisible] = useState(false);
  const toggleFS = ()=>{
    if(!isFullscreen){
      setIsFullscreen(true);
      FShandle.enter();
    }else{
      setIsFullscreen(false);
      FShandle.exit();
    }
  }

  const toggleShare = ()=>{
    if(!shareVisible){ 
      setShareVisible(true);
    }else{ 
      setShareVisible(false);
    }
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <ul className="nav-menu active">
            <li className="nav-item ">
              <NavLink
                to= "/apps"
                activeclassname="active"
                className={rootActive? "nav-links active": "nav-links"}
                onClick={()=>{setRootActive(false)}}
              >
                Apps
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/info"
                activeclassname="active"
                className="nav-links"
                onClick={()=>{setRootActive(false)}}
              >
                Info
              </NavLink>
            </li>
          </ul>
          
        </div>
        <i className="share-button fas fa-share-nodes" onClick={toggleShare}></i>
        <i className={isFullscreen? "fullscreen fas fa-compress" : "fullscreen fas fa-expand"} onClick={toggleFS} ></i>
      </nav>
      <Share shareVisible= {shareVisible} setShareVisible={setShareVisible}></Share>
    </>
  );
}

export default NavBar;